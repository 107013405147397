import styled from '@emotion/styled'
import { space, breakpoints } from '../../utils/styles'

export const Wrapper = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: RGBA(0, 0, 0, 0.5);
  color: ${({ dark }) => (dark ? 'white' : 'black')};
  z-index: 40;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  overflow-x: hidden;
  overflow-y: scroll;

  @media (max-width: ${breakpoints.s}px) {
    display: ${({ visible }) => (visible ? 'block' : 'none')};
  }
`

export const Modal = styled.div`
  height: auto;
  max-width: 600px;
  min-width: 300px;
  background-color: ${({ dark }) => (dark ? 'darkgrey' : 'white')};
  margin-top: 120px;
  padding: ${space[6]};

  @media (max-width: ${breakpoints.s}px) {
    margin-top: 2rem;
    margin-bottom: 10rem;
  }
`

export const CloseIconWrapper = styled.div`
  cursor: pointer;
`
