import React, { useRef, forwardRef } from 'react'

import { Container } from '../../utils/styles'
import { Wrapper, Modal, CloseIconWrapper } from './styles'
import { useOuterClick } from '../../utils/hooks'
import { CloseIcon } from '../ui/icons'

const Popup = forwardRef(({ visible, toggle, children }, ref) => {
  /* const containerRef = useRef(null)
  useOuterClick(function () {
    if (visible) toggle()
  }, containerRef) */

  return (
    <Wrapper ref={ref} visible={visible}>
      <Container /* ref={containerRef} */>
        <Modal>
          <CloseIconWrapper style={{ float: 'right' }} onClick={() => toggle()}>
            <CloseIcon />
          </CloseIconWrapper>

          {children}
        </Modal>
      </Container>
    </Wrapper>
  )
})

export default Popup
